import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Section } from '../components/UI';
import Layout from '../components/layout';
import Seo from '../components/seo';
import {  StaticImage } from 'gatsby-plugin-image';
import { EMOTION_BREAKPOINTS } from '../constants/breakpoints';

const ContentContainer = styled.div`
  h1 {
    color: ${({theme}) => theme.colors.text};
    font-weight: 400;
    margin-bottom: 20px;
    font-size: 24px;
  }
  p {
    color: #8E8E8E;
    font-size: 16px;
    margin-bottom: 0;
  }
  padding: 73px 40px 0;
  ${EMOTION_BREAKPOINTS.sm} {
    h1 { 
      font-size: 36px;
    }

  }
`


const UnderConstruction = () => (
  <Layout>
    <Seo title="Under Construction" />
    <Section
      css={css`
        text-align: center;
        padding: 97px 35px 20px;
      `}
    >

       <StaticImage
          src="../images/under-construction.svg"
          alt="contact"
          width="515"
        />
        <ContentContainer>
          <h1>We’re not quite ready yet!</h1>
          <p>Subscribe to our mailing list to get updates!</p>
        </ContentContainer>
    </Section>
  </Layout>
);


export default UnderConstruction;
